import { PollyClient } from '@aws-sdk/client-polly';
import { fetchAuthSession } from '@aws-amplify/auth';

export async function initializePollyClient() {
  try {
    const session = await fetchAuthSession();

    if(!session || !session.credentials) {
      throw new Error("Credenziali non trovate nella sessione")
    }

    const credentials = session.credentials;

    const pollyClient = new PollyClient({
      region: "eu-central-1",
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken
      }
    });

    return pollyClient;
  } catch (error) {
    console.error("Errore nel recuper delle credenziali o della sessione:", error);
  }
}