import { SynthesizeSpeechCommand } from '@aws-sdk/client-polly';
import { initializePollyClient } from './pollyClient';

const audio = new Audio();

export const speakWithPolly = async (text) => {
  const params = {
    OutputFormat: "mp3",
    Text: text,
    VoiceId: "Bianca",
    Engine: "neural"
  };

  const pollyClient = await initializePollyClient();
  
  try {
    const data = await pollyClient.send(new SynthesizeSpeechCommand(params));
    const { AudioStream } = data;

    if (AudioStream) {
      // Converti steram audio in blob
      const reader = AudioStream.getReader();
      const chunks = [];

      reader.read().then(function processText({ done, value }) {
        if (done) {
          const audioBlob = new Blob(chunks, { type: 'audio/mpeg' });            
          audio.src = URL.createObjectURL(audioBlob);
          audio.play().catch(e => console.error('Errore nella riproduzione audio: ', e))
          return;
        }

        chunks.push(value);
        return reader.read().then(processText);
      })

    } else {
      console.log("Errore nello stream ricevuto.")
    }
  } catch (err) {
    console.error("Errore nella sintesi vocale:", err);
  }
};

export const stopAudio = () => {
  if (audio) {
    audio.pause();
    audio.currentTime = 0;
    console.log("Stop audio eseguito");
  }
};